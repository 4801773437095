import {ProductFeedbackTypeDTO, ProductFeedbackTypeEntity} from './useFeedbackType'
import {Logger} from '../../../utils/logger.utils'
import {useParseData} from '../../../utils/parse.utils'

export const useFeedbackUtils = (settings: any) => {
  const {generateDefaultFieldDirectus, getTransItemMulti, getImageField} = useParseData()
  // const { generateProductDTO } = useProductUtils(settings)
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);
  const generateFeedbackDTO = (
    item: ProductFeedbackTypeEntity,
    languageCode: string
  ): ProductFeedbackTypeDTO | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        show_mobile_app: item.show_mobile_app ?? false,
        image: item.image ? getImageField(item, 'image') : null,
        name: getTransItemMulti(item, languageCode, 'name'),
        age: getTransItemMulti(item, languageCode, 'age'),
        job: getTransItemMulti(item, languageCode, 'job'),
        content: getTransItemMulti(item, languageCode, 'content')
      }

      // if (item.product && typeof item.product === 'object') {
      //     output['product'] = generateProductDTO(item.product as any, languageCode)
      // }

      //output['lang_switch'] = generateLangSwitch('feedbacks_detail', languageCode, item)
      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateProductFeedbackDTO : ', e.message)
      return {}
    }
  }

  return {
    generateFeedbackDTO,
    logger
  }
}

